import { Component, Input } from '@angular/core';
import { AppRoutes } from 'src/core/enums/app-routes.enum';
import { OrganizationData } from 'src/core/interfaces/organization.interface';
import { AuthService } from 'src/core/services/auth.service';
import { NavItemConfig, config } from './side-nav-bar.config';

@Component({
  selector: 'app-side-nav-bar',
  templateUrl: './side-nav-bar.component.html',
  styleUrls: ['./side-nav-bar.component.scss'],
})
export class SideNavBarComponent {
  public appRoutes = AppRoutes;
  public navConfig = config;
  public organization: OrganizationData;

  @Input() public open: boolean | null = true;

  constructor() {
    this.organization = AuthService.getOrganization();
  }

  public navItem(item: NavItemConfig): string {
    const route = window.location.href;

    return route.includes(item.path)
      ? `icon-${item.icon}-filled`
      : `icon-${item.icon}`;
  }
}
