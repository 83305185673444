import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { DatePipe } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import {
  MAT_SNACK_BAR_DEFAULT_OPTIONS,
  MatSnackBar,
} from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MsalModule, MsalRedirectComponent } from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { EffectsModule } from '@ngrx/effects';
import { Store, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from 'environments/environment';
import { MSALScopes } from 'src/core/enums/msal.enum';
import { AuthInterceptor } from 'src/core/interceptors/auth.interceptor';
import { IpTrackerInterceptor } from 'src/core/interceptors/ip-tracker.interceptor';
import { IsDisabledRoutePipe } from 'src/core/pipes/routing/is-disabled-route.pipe';
import { TranslatePipe } from 'src/core/pipes/translate.pipe';
import { LoaderComponent } from 'src/core/shared/components/loader/loader.component';
import { metaReducers, reducers } from 'src/core/store/app.state';
import { NotificationsEffects } from 'src/core/store/notifications/notifications.effects';
import { TeamEffects } from 'src/core/store/team/team.effects';
import { ErrorRouteComponent } from '../core/shared/components/error-route/error-route.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './footer/footer.component';
import { NavComponent } from './navigation/nav/nav.component';
import { SideNavBarComponent } from './navigation/side-nav-bar/side-nav-bar.component';
import { IconComponent } from 'src/core/shared/components/icon/icon.component';
import { AuthModeEffects } from 'src/core/store/auth/auth.effects';

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    SideNavBarComponent,
    FooterComponent,
    ErrorRouteComponent,
    LoaderComponent,
    IsDisabledRoutePipe,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MatIconModule,
    MatTooltipModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    EffectsModule.forRoot([
      AuthModeEffects,
      TeamEffects,
      NotificationsEffects,
      // HydrationEffects
    ]),
    IconComponent,
    StoreDevtoolsModule.instrument({
      maxAge: 25,
    }),
    MsalModule.forRoot(
      new PublicClientApplication({
        auth: {
          clientId: environment.microsoftSSO.clientId,
          authority: `${environment.microsoftSSO.cloudInstanceId}/common`,
          redirectUri: environment.microsoftSSO.redirectURI,
        },
        cache: {
          cacheLocation: 'localStorage',
          storeAuthStateInCookie: isIE,
        },
      }),
      {
        interactionType: InteractionType.Redirect,
        authRequest: {
          scopes: MSALScopes,
        },
      },
      {
        interactionType: InteractionType.Redirect,
        protectedResourceMap: new Map([
          [environment.microsoftSSO.graphEndpoint, MSALScopes],
        ]),
      },
    ),
  ],
  providers: [
    DatePipe,
    TranslatePipe,
    IsDisabledRoutePipe,
    MatSnackBar,
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 3000 } },
    Store,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: IpTrackerInterceptor,
      multi: true,
    },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline' },
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
