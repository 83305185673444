import { Injectable } from '@angular/core';
import { Actions, OnInitEffects, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { map } from 'rxjs/operators';

import { AuthMode } from 'src/core/enums/permissions.enum';
import { LocalStorage } from 'src/core/enums/store-constants.enum';
import { AuthModeActions, initAuthModeStore } from './auth.actions';

@Injectable()
export class AuthModeEffects implements OnInitEffects {
  initAuthStore$ = createEffect(() =>
    this.actions$.pipe(
      ofType(initAuthModeStore),
      map(() => {
        const localStorageAuthMode: AuthMode =
          (localStorage.getItem(LocalStorage.authMode) as AuthMode) ||
          AuthMode.Regular;

        return AuthModeActions.setMode({ mode: localStorageAuthMode });
      }),
    ),
  );

  constructor(private actions$: Actions) {}

  ngrxOnInitEffects(): Action {
    return initAuthModeStore();
  }
}
