import { Injectable } from '@angular/core';
import { Actions, OnInitEffects, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { EMPTY } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { TeamApiService } from 'src/app/team/api/team.api.service';
import { LocalStorage } from 'src/core/enums/store-constants.enum';
import { TeamData } from 'src/core/interfaces/team/team.interface';
import { TeamsActions, initTeamStore } from './team.actions';
import { teamStoreFeature } from './team.selectors';

@Injectable()
export class TeamEffects implements OnInitEffects {
  loadTeamsFromDB$ = createEffect(() =>
    this.actions$.pipe(
      ofType(initTeamStore),
      withLatestFrom(this.store.select(teamStoreFeature)),
      mergeMap(() => {
        if (localStorage.getItem(LocalStorage.jwtToken)) {
          return this.apiService.getTeamMembers().pipe(
            map((response: TeamData[]) =>
              TeamsActions.loadTeamsFromDB({
                teams: response,
                fetched: true,
              }),
            ),
            catchError((e) => {
              console.error('catchError: ', e);

              return EMPTY;
            }),
          );
        }

        return EMPTY;
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private apiService: TeamApiService,
    private store: Store,
  ) {}

  ngrxOnInitEffects(): Action {
    return initTeamStore();
  }
}
